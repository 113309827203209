import React, { useState } from 'react';
import { Box, TextField, MenuItem, Grid, Typography, Button, Alert } from '@mui/material';
import { contactUs } from '../../services/contact-service';
import './contact-us.css';

const ContactUsComponent = () => {
    const defaultFormValues = { name: '', surname: null, email: '', phone: null, message: '' };
    const [formValues, setFormValues] = useState(defaultFormValues);
    const [isError, setIsError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);
    const [emailError, setEmailError] = useState(false);

    const handleSubmit = () => {
        const requiredFields = ['name', 'email', 'message'];
        const allFieldsFilled = requiredFields.every(field => formValues[field] && formValues[field].trim() !== '');

        if (!allFieldsFilled) {
            setIsError(true);
            setErrorMessage('Please fill in all required fields');
        } else {
            if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formValues.email)) {
                setIsError(true);
                setEmailError(true);
                setErrorMessage('Please enter valid email format');
            } else {
                console.log('zoshto?')
                contactUs(formValues).then(res => setIsSuccess(true)).catch(er => setIsError(true));
            }
        }
    }

    const handleInputChange = (e) => {
        setIsError(false);
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    const handleErrorBorderStyle = (prop) => {
        if (emailError && prop === 'email') {
            return {
                '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                        borderColor: isError && emailError ? 'red' : 'default',
                    },
                    '&:hover fieldset': {
                        borderColor: isError && emailError ? 'red' : 'default',
                    },
                    '&.Mui-focused fieldset': {
                        borderColor: isError && emailError ? 'red' : 'default',
                    }
                }
            }
        }

        return {
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderColor: isError && formValues[prop] === '' ? 'red' : 'default',
                },
                '&:hover fieldset': {
                    borderColor: isError && formValues[prop] === '' ? 'red' : 'default',
                },
                '&.Mui-focused fieldset': {
                    borderColor: isError && formValues[prop] === '' ? 'red' : 'default',
                },
            }
        }
    }

    return (
        <>
            {isSuccess ? (
                <div className='parent-container-cu' >
                    <div className='child-container-cu'>
                        <Alert className='alert-element-cu' severity="success">
                            <strong><h3>Thank you. Your inquiry has been submitted. We will get in touch with you as soon as possible.</h3></strong>
                        </Alert>
                        <div style={{ display: 'flex', gap: '2.5px', flexDirection: 'column' }}>
                            <Button onClick={() => window.location.reload()} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#6c94a6', color: '#fff', borderColor: '#6c94a6' } }}>Send another message</Button>
                            <Button onClick={() => window.location.replace('/')} variant='outlined' sx={{ textTransform: 'none', '&:hover': { backgroundColor: '#6c94a6', color: '#fff', borderColor: '#6c94a6' } }}>Go to Homepage</Button>
                        </div>
                    </div>
                </div>
            ) : (
                <>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        className='content-cu'
                        // sx={{ maxWidth: '600px', margin: '20px auto', padding: '16px', border: '1px solid #ccc', borderRadius: '8px' }}
                    >
                        <p className='paragraph-cu'>
                            Want to learn more about partnering with us, becoming an expert or requesting support? Do you have ideas how we could improve or collaborate?  
                        </p>
                        <p>
                            Please don’t hesitate to reach out, we will get back to you as soon as possible.
                        </p>
                        <Typography variant="h5" mb={'25px'} color={'primary'} align="center">
                            Contact Us
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Name"
                                    id="name"
                                    defaultValue=""
                                    size="small"
                                    name='name'
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    sx={handleErrorBorderStyle('name')}
                                    helperText={
                                        <span className='warning-msg-span-cu'>
                                            {isError && formValues.name === '' ? 'This field is required' : ''}
                                        </span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Surname"
                                    id="surname"
                                    defaultValue=""
                                    name='surname'
                                    size="small"
                                    onChange={e => handleInputChange(e)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Email"
                                    id="email"
                                    type="email"
                                    name='email'
                                    defaultValue=""
                                    size="small"
                                    sx={handleErrorBorderStyle('email')}
                                    onChange={e => handleInputChange(e)}
                                    fullWidth
                                    helperText={
                                        <span className='warning-msg-span-cu'>
                                            {isError && formValues.email === '' ? 'This field is required' :
                                                isError && emailError ? 'Incorrect email format' : ''}
                                        </span>

                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    label="Phone"
                                    id="phone"
                                    type="tel"
                                    name='phone'
                                    defaultValue=""
                                    size="small"
                                    onChange={e => handleInputChange(e)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    placeholder="Write your message here"
                                    label="Your message"
                                    id="outlined-size-small"
                                    name='message'
                                    multiline
                                    rows={3}
                                    maxRows={6}
                                    sx={handleErrorBorderStyle('message')}
                                    onChange={e => handleInputChange(e)}
                                    fullWidth
                                    helperText={
                                        <span className='warning-msg-span-cu'>
                                            {isError && formValues.message === '' ? 'This field is required' : ''}
                                        </span>
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                {/* <Button onClickCapture={e => e.type === 'click'? handleSubmit(e) : console.log(e.type)} onKeyDown={(e) => e.key === 'Enter' ? handleSubmit(e) : null} variant="contained" color="primary" fullWidth>
                                    Submit
                                </Button> */}
                                <Button onClick={handleSubmit} variant="contained" color="primary" fullWidth>
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {isError && (
                                    <Alert variant='filled' severity='error'>
                                        Error: {errorMessage}
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>
                    </Box >
                </>
            )}
        </>);
};


export default ContactUsComponent;