// import { Delete, UploadFile } from "@mui/icons-material";
import { Alert, Box, Button, Checkbox, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, IconButton, InputLabel, ListItemText, MenuItem, Radio, RadioGroup, Select, TextField, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import axios from 'axios';
// import { useRef } from "react";
import { hospitalsApplyFormProps, requiredHospitalsApplyFormProps } from "../../consts/form-helpers/form-consts";
import { hospitalApply, verifyCaptcha } from "../../services/apply-service";
import HCaptcha from '@hcaptcha/react-hcaptcha';
import './request-support-apply.css';

const RequestSupportApplyComponent = () => {
    const HCAPTCHA_KEY = 'd2169502-dac0-4a8c-b8c0-18b4da968a86';
    // const fileInputRef = useRef(null);
    const [selectedFields, setSelectedFields] = useState([]);
    // const [acceptConsent, setAcceptConsent] = useState('');
    const [acceptTerms, setAcceptTerms] = useState('');
    const [confirmInformationIsTrue, setConfirmInformationIsTrue] = useState('');
    const [countryOptions, setCountryOptions] = useState([]);
    // const [file, setFile] = useState(null);
    const [error, setError] = useState(null);
    const [emailError, setEmailError] = useState(false);
    const [emptyFormProps, setEmptyFormProps] = useState([]);
    // const [consentError, setConsentError] = useState('');
    const [acceptTermsError, setAcceptTermsError] = useState('');
    const [confirmInformationIsTrueError, setConfirmInformationIsTrueError] = useState('');
    const [formData, setFormData] = useState({ ...hospitalsApplyFormProps });
    const [isSuccess, setIsSuccess] = useState(false);
    const [captchaToken, setCaptchaToken] = useState('');

    const handleCaptchaVerify = token => {
        setError(null);
        setCaptchaToken(token);
    };

    const handleInputChange = e => {
        setError(null);
        setFormData({ ...formData, [e.target.name]: e.target.value });

        if (emptyFormProps.includes(e.target.name)) {
            setEmptyFormProps((props) => props.filter(prop => prop !== e.target.name));
        }
    };

    const handleAcceptTermsChange = e => {
        setAcceptTerms(e);
        setFormData({ ...formData, terms_and_conditions: e === 'yes' });
        if (e !== 'no') {
            setAcceptTermsError('');
        };
    };

    // const handleAcceptConsent = e => {
    //     setAcceptConsent(e);
    //     setFormData({ ...formData, consent: e === 'yes' });
    //     if (e !== 'no') {
    //         setConsentError('');
    //     };
    // };

    const handleConfirmInformationIsTrue = e => {
        setConfirmInformationIsTrue(e);
        setFormData({ ...formData, information_accuracy_confirmation: e === 'yes' });
        if (e !== 'no') {
            setConfirmInformationIsTrueError('');
        };
    };

    const handleFieldChange = (e) => {
        setError(null);
        setEmptyFormProps((props) => props.filter(prop => prop !== 'field_of_expertise_ids'));

        if (e.target.value.length <= 3) {
            setSelectedFields(e.target.value);
            setFormData({ ...formData, field_of_expertise_ids: e.target.value })
        }
    };

    const validateForm = () => {
        let isFormValid = true;

        for (let prop of requiredHospitalsApplyFormProps) {
            if (formData[prop] === '' || formData[prop] === null || formData[prop] === false) {
                // if (prop === 'consent') {
                //     setConsentError('You must give consent to proceed.')
                //     isFormValid = false;
                // };

                if (prop === 'terms_and_conditions') {
                    setAcceptTermsError('You must accept the terms and conditions to procede.')
                    isFormValid = false;
                };

                if (prop === 'information_accuracy_confirmation') {
                    setConfirmInformationIsTrueError('You must confirm the accuracy of information provided.')
                    isFormValid = false;
                };

                setEmptyFormProps((prev) => [...prev, prop]);
                setError('Please fill out all required fields');
                isFormValid = false;
            };

            // if (prop === 'consent' && acceptConsent === 'no') {
            //     setConsentError('You must give consent to proceed.');
            //     setError('Please fill out all required fields');
            //     isFormValid = false;
            // };

            if (prop === 'terms_and_conditions' && acceptTerms === 'no') {
                setAcceptTermsError('You must accept the terms and conditions to procede.');
                setError('Please fill out all required fields');
                isFormValid = false;
            };

            if (prop === 'information_accuracy_confirmation' && confirmInformationIsTrue === 'no') {
                setConfirmInformationIsTrueError('You must confirm the accuracy of information provided.');
                setError('Please fill out all required fields');
                isFormValid = false;
            };
        };

        return isFormValid;
    }

    const handleSubmit = async () => {
        if (validateForm()) {
            verifyCaptcha({token: captchaToken}).then((res) => {
                if (res.success) {
                    hospitalApply(formData).then((res) => {}).catch(er => console.log(er));
                    setIsSuccess(true);
                    window.scrollTo({ top: 0, behavior: 'smooth' });
                } else {
                    setError('Captcha verification failed. Please try again.');
                }
            })
            
        }
    }

    // const handleFileChange = (e) => {
    //     setEmptyFormProps((props) => props.filter(prop => prop !== 'letter_of_commitment_base64'));
    //     const file = e.target.files[0];
    //     const fileExtension = file?.name.split('.').pop();
    //     const reader = new FileReader();
    //     setFile(e.target.files[0]);

    //     reader.onloadend = () => {
    //         const base64String = reader.result.split(',')[1];
    //         setFormData({ ...formData, letter_of_commitment_base64: base64String, letter_of_commitment_base64_extension: fileExtension });
    //     };

    //     if (file) {
    //         reader.readAsDataURL(file);
    //     }
    // };

    // const handleFileRemove = () => {
    //     setFile(null);
    //     setFormData({ ...formData, letter_of_commitment_base64: null })
    //     if (fileInputRef.current) {
    //         fileInputRef.current.value = '';
    //     }
    // };

    const handleEmailBlur = () => { setEmailError(!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email) ? 'Invalid email format' : '') };

    useEffect(() => {
        setError(null);

        axios.get('https://restcountries.com/v3.1/all')
            .then((response) => {
                const newCountries = response.data.map(country => ({
                    label: country.name.common === 'North Macedonia' ? 'Macedonia' : country.name.common,
                    value: country.name.common === 'North Macedonia' ? 'Macedonia' : country.name.common,
                    flag: country.flags.png
                }));

                newCountries.sort((a, b) => a.label.localeCompare(b.label));

                setCountryOptions(newCountries);
            })
            .catch((error) => {
                console.log(error);
            });
    }, []);

    //TODO - CHANGE THE 3 HARDCODED COLLECTIONS BELOW WITH A GET CALL FROM THE DB!

    const level = [
        {
            value: 1,
            label: 'Secondary',
        },
        {
            value: 2,
            label: 'Tertiary'
        },
        {
            value: 3,
            label: 'Academic'
        },
        {
            value: 4,
            label: 'Local'
        }
    ];

    const type = [
        {
            value: 1,
            label: 'Public'
        },
        {
            value: 2,
            label: 'Private, non-profit'
        },
        {
            value: 3,
            label: 'Private, for-profit'
        }
    ];

    const fieldsOfExpertise = [
        {
            value: 1,
            label: 'Infectious diseases management'
        },
        {
            value: 2,
            label: 'Clinical microbiology'
        },
        {
            value: 3,
            label: 'Diagnostic stewardship and laboratory services'
        },
        {
            value: 4,
            label: 'Infection prevention and control'
        },
        {
            value: 5,
            label: 'Antimicrobial Stewardship'
        }
    ];

    return (
        <>
            <div className="content-rsa">
                <p>
                    We are happy to receive your request for support and work with you towards high quality infectious disease care everywhere.
                </p>
                <p>
                    By filling out the form below, we can gain an understanding of your needs and the context you work in. We will review your application, and get back in touch with you regarding the matching procedure as soon as possible.
                </p>
                <p>
                    If you have any questions in the meantime, please don’t hesitate to reach out to our SPICE Coordinator at <a href="miriam.sijtsma@radboudumc.nl">miriam.sijtsma@radboudumc.nl</a>
                </p>
            </div>
            {isSuccess ? (
                <div className="success-msg-rsa-div">
                    <Alert className="success-msg-alert-rsa" severity="success">
                        <strong><h3>Thank you. Your application was successfully sent. You will be contacted for further details.</h3></strong>
                    </Alert>
                </div>
            ) : (
                <>
                    <Typography variant="h4" color={'primary'} m={2.5} textAlign={'center'}>Request Support</Typography>
                    <Box
                        component="form"
                        noValidate
                        autoComplete="off"
                        className="content-rsa-form"
                    >


                        <Grid container spacing={2}>
                            <Typography variant="h6" color={'primary'} textAlign={'center'}>Hospital/Institution info</Typography>
                            <Grid item xs={12}>
                                <TextField
                                    label="Hospital/institution name"
                                    id="outlined-size-small"
                                    name="name"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('name') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('name')}

                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="Address"
                                    id="outlined-size-small"
                                    name="address"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('address') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('address')}

                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    label="City"
                                    id="outlined-size-small"
                                    name="city"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('city') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('city')}

                                />
                            </Grid>
                            <Grid item xs={12} sm={4}>
                                <TextField
                                    id="outlined-select-country"
                                    name="country"
                                    select
                                    label="Country"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('country') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('country')}
                                >
                                    {countryOptions.map((option) => (
                                        <MenuItem sx={{ '& > img': { mr: 2, flexShrink: 0 } }} key={option.value} value={option.value}>
                                            <img
                                                loading="lazy"
                                                width="20"
                                                srcSet={option.flag}
                                                src={option.flag}
                                                alt=""
                                            />
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Legal representative/authorized person"
                                    id="outlined-size-small"
                                    name="legal_representative_authorized_person"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('legal_representative_authorized_person') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('legal_representative_authorized_person')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Position"
                                    id="outlined-size-small"
                                    name="legal_representative_authorized_person_position"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('legal_representative_authorized_person_position') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('legal_representative_authorized_person_position')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Contact person"
                                    id="outlined-size-small"
                                    name="contact_person"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('contact_person') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('contact_person')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Position"
                                    id="outlined-size-small"
                                    name="contact_person_position"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('contact_person_position') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('contact_person_position')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Phone number"
                                    id="outlined-size-small"
                                    name="phone_number"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('phone_number') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('phone_number')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    label="Email"
                                    id="outlined-size-small"
                                    name="email"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    type="email"
                                    helperText={emailError !== '' ? emailError : emptyFormProps.includes('email') ? 'This field is required' : ''}
                                    error={!!emailError || !!emptyFormProps.includes('email')}
                                    onBlur={handleEmailBlur}
                                    onChange={e => handleInputChange(e)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <TextField
                                    placeholder="Briefly describe size, types of specialties, population coverage, etc"
                                    label="About the hospital"
                                    id="outlined-size-small"
                                    name="about_the_hospital"
                                    multiline
                                    rows={5}
                                    maxRows={10}
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('about_the_hospital') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('about_the_hospital')}
                                />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-select-level"
                                    name="institution_level_id"
                                    select
                                    label="Level"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('institution_level_id') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('institution_level_id')}
                                >
                                    {level.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField
                                    id="outlined-select-country"
                                    name="institution_type_id"
                                    select
                                    label="Type"
                                    defaultValue=""
                                    size="small"
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('institution_type_id') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('institution_type_id')}
                                >
                                    {type.map((option) => (
                                        <MenuItem key={option.value} value={option.value}>
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </TextField>
                            </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                            <Typography variant="h6" color={'primary'} mt={5} textAlign={'center'}>Requested expert support</Typography>
                            <Grid item xs={12}>
                                <FormControl fullWidth error={!!emptyFormProps.includes('field_of_expertise_ids')}>
                                    <InputLabel id="field-of-expertise-label">Field of Expertise</InputLabel>
                                    <Select
                                        labelId="field-of-expertise-label"
                                        id="field-of-expertise"
                                        multiple
                                        value={selectedFields}
                                        onChange={handleFieldChange}
                                        renderValue={(selected) => selected.map(value => {
                                            const option = fieldsOfExpertise.find(opt => opt.value === value);
                                            return option ? option.label : '';
                                        }).join(', ')}
                                        size="large"
                                    >
                                        {fieldsOfExpertise.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                <Checkbox checked={selectedFields.indexOf(option.value) > -1} />
                                                <ListItemText primary={option.label} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {emptyFormProps.includes('field_of_expertise_ids') && (<FormHelperText>This field is required</FormHelperText>)}

                                </FormControl>
                            </Grid>
                            <Grid item xs={12} sm={12}>
                                <FormHelperText>What is the reason for your request for support?</FormHelperText>
                                <TextField
                                    placeholder="Briefly describe the requested support"
                                    label="Requested support description"
                                    id="outlined-size-small"
                                    name="requested_support_description"
                                    multiline
                                    rows={5}
                                    maxRows={10}
                                    fullWidth
                                    onChange={e => handleInputChange(e)}
                                    helperText={emptyFormProps.includes('requested_support_description') ? 'This field is required' : ''}
                                    error={!!emptyFormProps.includes('requested_support_description')}
                                />
                            </Grid>
                            {/* <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    startIcon={<UploadFile />}
                                    fullWidth
                                    sx={{ textTransform: 'none', marginBottom: '2.5vh' }}
                                >
                                    Letter of commitment (Please attach letter signed by the authorized person)
                                    <input
                                        type="file"
                                        hidden
                                        onChange={handleFileChange}
                                        ref={fileInputRef}
                                    />
                                </Button>
                                {emptyFormProps.includes('letter_of_commitment_base64') && (
                                    <>
                                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body1">
                                                    <strong style={{ color: '#d32f2f' }}>Letter of commitment is required</strong>
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                        <Divider
                                            variant="fullWidth"
                                            sx={{ backgroundColor: '#6c94a6', mb: 2, height: '1.75px' }}
                                        />
                                    </>
                                )}
                                {file && (
                                    <>
                                        <Grid container justifyContent="center" alignItems="center" spacing={1}>
                                            <Grid item>
                                                <Typography variant="body2">
                                                    <strong>{file.name}</strong>
                                                </Typography>
                                            </Grid>
                                            <Grid item>
                                                <Tooltip title="Remove file">
                                                    <IconButton onClick={handleFileRemove} sx={{ color: '#d32f2f' }}>
                                                        <Delete />
                                                    </IconButton>
                                                </Tooltip>
                                            </Grid>
                                        </Grid>
                                        <Divider
                                            variant="fullWidth"
                                            sx={{ backgroundColor: '#6c94a6', mb: 2, height: '1.75px' }}
                                        />
                                    </>
                                )}
                            </Grid> */}
                        </Grid>
                        {/* <Grid item xs={12} sm={12} mb={'2.5vh'} mt={'2.5vh'}>
                            <TextField
                                placeholder="Comments and additional information"
                                label="Comments and additional information"
                                id="outlined-size-small"
                                name="comments_and_additional_info"
                                multiline
                                rows={3}
                                maxRows={6}
                                fullWidth
                                onChange={e => handleInputChange(e)}
                            // helperText={emptyFormProps.includes('comments_and_additional_info') ? 'This field is required' : ''}
                            // error={!!emptyFormProps.includes('comments_and_additional_info')}
                            />
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item>
                                {consentError && <FormHelperText><b style={{ color: '#d32f2f' }}>{consentError}</b></FormHelperText>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container gap="10px" alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <FormLabel component="legend"><small><strong>We hereby give consent to Radboudumc to use the above data for the purposes of the SPICE registry:</strong></small></FormLabel>
                            </Grid>
                            <Grid item>
                                <RadioGroup
                                    aria-label="accept-terms"
                                    name="accept-consent"
                                    value={acceptConsent}
                                    onChange={handleAcceptConsent}
                                    row
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </Grid>

                        </Grid> */}
                        {/* <Grid item xs={12} container>
                            <Grid item>
                                {acceptTermsError && <FormHelperText><b style={{ color: '#d32f2f' }}>{acceptTermsError}</b></FormHelperText>}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container gap="10px" alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <FormLabel component="legend"><small><strong>We accept the terms and conditions of the SPICE expert support</strong></small></FormLabel>
                            </Grid>
                            <Grid item>
                                <RadioGroup
                                    aria-label="accept-terms"
                                    name="accept-terms"
                                    value={acceptTerms}
                                    onChange={handleAcceptTermsChange}
                                    row
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </Grid>

                        </Grid>
                        <Grid item xs={12} container >
                            <Grid item>
                                {confirmInformationIsTrueError && <FormHelperText><b style={{ color: '#d32f2f' }}>{confirmInformationIsTrueError}</b></FormHelperText>}
                            </Grid>
                        </Grid> */}
                        <Grid item xs={12} container>
                            <Grid item>
                                {acceptTermsError && (
                                    <FormHelperText>
                                        <b style={{ color: '#d32f2f' }}>{acceptTermsError}</b>
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container gap="10px" alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <FormLabel component="legend">
                                    <small>
                                        <strong>We accept the terms and conditions of the SPICE expert support</strong>
                                    </small>
                                </FormLabel>
                            </Grid>
                            <Grid item>
                                <Checkbox
                                    checked={acceptTerms === 'yes'}
                                    onChange={(e) => handleAcceptTermsChange(e.target.checked ? 'yes' : 'no')}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container>
                            <Grid item>
                                {confirmInformationIsTrueError && (
                                    <FormHelperText>
                                        <b style={{ color: '#d32f2f' }}>{confirmInformationIsTrueError}</b>
                                    </FormHelperText>
                                )}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container gap="10px" alignItems="center" justifyContent="space-between">
                            <Grid item>
                                <FormLabel component="legend">
                                    <small>
                                        <strong>We confirm the above information is true</strong>
                                    </small>
                                </FormLabel>
                            </Grid>
                            <Grid item>
                                <Checkbox
                                    checked={confirmInformationIsTrue === 'yes'}
                                    onChange={(e) => handleConfirmInformationIsTrue(e.target.checked ? 'yes' : 'no')}
                                />
                            </Grid>
                        </Grid>
                        <Grid item xs={12} container gap="10px" alignItems="center" justifyContent="space-between">
                            {/* <Grid item>
                                <FormLabel component="legend"><small><strong>We confirm the above information is true:</strong></small></FormLabel>
                            </Grid>
                            <Grid item>
                                <RadioGroup
                                    aria-label="accept-terms"
                                    name="accept-terms"
                                    value={confirmInformationIsTrue}
                                    onChange={handleConfirmInformationIsTrue}
                                    row
                                >
                                    <FormControlLabel value="yes" control={<Radio />} label="Yes" />
                                    <FormControlLabel value="no" control={<Radio />} label="No" />
                                </RadioGroup>
                            </Grid> */}
                            <Grid item xs={12}>
                                <div style={{ display: 'flex', width: 'fit-content', alignSelf: 'flex-start' }}>
                                    <HCaptcha sitekey={HCAPTCHA_KEY} onVerify={handleCaptchaVerify} />
                                </div>
                            </Grid>

                            <Grid item xs={12} mt='25px' mb='25px'>
                                <FormLabel component="legend">
                                    <small>
                                        <strong style={{ color: '#1565C0' }}>
                                            After processing your request and upon matching with an expert, we will ask you for a letter of commitment from an authorized person from your institution.
                                        </strong>
                                    </small>
                                </FormLabel>
                                <FormLabel component="legend">
                                    <small>
                                        <strong style={{ color: '#1565C0' }}>
                                            If you agree to register, any processing of provided data will comply with EU Regulations 45/2001 and 2018/1725. As the host of the registry, Radboudumc is the main data controller of this processing operation.
                                        </strong>
                                    </small>
                                </FormLabel>
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    component="label"
                                    fullWidth
                                    sx={{ textTransform: 'none', marginBottom: '2.5vh', backgroundColor: '#009E60', '&:hover': { backgroundColor: '#009E60' } }}
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {error && (
                                    <Alert variant="filled" severity="error">
                                        Error: {error}
                                    </Alert>
                                )}
                            </Grid>
                        </Grid>

                    </Box>
                </>
            )}
        </>
    )
}

export default RequestSupportApplyComponent;