import './how-can-spice-help-my-facility.css';
import { Accordion, AccordionDetails, AccordionSummary } from "@mui/material";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AppRoutes } from "../../consts/routes/app-routes";
import teamImage from '../../media/Indonesia-team-pictures.png';

const HowCanSpiceHelpMyFacility = () => {
    return (
        <>
            <div class="content-hcshmf">
                <p class="intro">
                    SPICE aims to offer sustainable capacity development, equipping hospitals to respond to the AMR threat in their own environment. Our experts are specialized and well-equipped to support you in delivering high quality infectious disease care. We are committed to make a meaningful and long-lasting contribution, working alongside you in your own workplace to exchange knowledge and skill.
                </p>
                <div className='image-container-for-hcshmf'>
                    <img className='team-img' src={teamImage} alt='team' />
                </div>
                <p class="intro">
                    Are you a healthcare institution that would benefit from expert support? Do you want to collaborate with us to contribute to quality infectious disease care everywhere?
                    Please see below for some frequently asked questions, reach out to us, or <a href={AppRoutes.RequestSupportApply}>apply here</a>.
                </p>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <h1>Who is eligible to apply for support?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Support is available for any hospital or healthcare facility that would benefit from implementation support of their efforts to ensure optimal infectious disease management. We will require a brief description of your facility, approval of the request from an authorized person, an a description of the area you desire support                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <h1>Which areas can I request support in?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>The SPICE registry consists of a wide range of experts with expertise in the following fields:                        </p>
                        <ul>
                            <li>
                                Infectious disease management
                            </li>
                            <li>
                                Antimicrobial Stewardship
                            </li>
                            <li>
                                Microbiology and diagnostics
                            </li>
                            <li>
                                Measurement of antimicrobial use
                            </li>
                            <li>
                                Infection prevention and control
                            </li>
                            <li>
                                Implementation science and behavior change
                            </li>
                        </ul>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <h1>What types of support can you offer?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            Following your request, we will start a careful matching procedure to ensure a suitable match with an expert. Depending on availability, the expert will be able to provide a combination of  online and on-site support. Usually, each host institution is assigned one expert consultant to work with them throughout the idea development, planning and implementation, over a period of six months to one year.
                        </p>
                    </AccordionDetails>
                </Accordion>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                    >
                        <h1>What will it cost me?</h1>
                    </AccordionSummary>
                    <AccordionDetails>
                        <p>
                            SPICE will cover all international travel costs for the expert. We expect the host institution to cover all in-country costs during site visits, which includes accommodation, subsistence and internal travel costs. In case this is not possible, please don’t hesitate to reach out to us so we can review your case and evaluate how we can support you. 
                        </p>
                    </AccordionDetails>
                </Accordion>
            </div>
        </>
    )
};

export default HowCanSpiceHelpMyFacility;