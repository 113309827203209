import { Avatar, Box, ImageList, ImageListItem, StyledEngineProvider, Typography } from '@mui/material';
import EdmeeBowles from '../../media/EdmeeBowles.jpg';
import HeimanWertheim from '../../media/HeimanWertheim.jpg';
import NedaMilevskaKostova from '../../media/NedaMilevskaKostova.jpg';
import SimonBroecheler from '../../media/SimonBroecheler.jpg';
import QuirijndeMast from '../../media/QuirijndeMast.jpg';
import JeroenSchouten from '../../media/JeroenSchouten.jpg';
import Miriam from '../../media/PP_Miriam.jpg';
import './spice-team.css';
import { useEffect } from 'react';

const SpiceTeamComponent = () => {
    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, []);
    
    return (
        <StyledEngineProvider injectFirst>
            <div className='parent-ctn-for-spice-team'>
                <div className='secretariat-container'>
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', margin: '10px 0' }}>
                        <Typography mb={'1vh'} variant="h6">
                            SPICE Secretariat
                        </Typography>
                        <Box className='team-member-box-c'>
                            <Avatar className='avatarImage' alt='Miriam Sijtsma' src={Miriam} />
                            <p>Miriam Sijtsma, SPICE Coordinator</p>
                        </Box>
                    </div>
                </div>
                <Typography mt={'5vh'} textAlign={'center'} variant="h6">
                        SPICE Steering committee
                    </Typography>
                <div className='container-for-members-of-team-spice'>
                    <Box className='team-member-box'>
                        <Avatar className='avatarImage' alt='Heiman Wertheim' src={HeimanWertheim} />
                        <p>Heiman Wertheim</p>
                    </Box>
                    <Box className='team-member-box'>
                        <Avatar className='avatarImage' alt='Edmee Bowles' src={EdmeeBowles} />
                        <p>Edmee Bowles</p>
                    </Box>
                    <Box className='team-member-box'>
                        <Avatar className='avatarImage' alt='Neda Milevska Kostova' src={NedaMilevskaKostova} />
                        <p>Neda Milevska Kostova</p>
                    </Box>
                    <Box className='team-member-box'>
                        <Avatar className='avatarImage' alt='Simon Broecheler' src={SimonBroecheler} />
                        <p>Simon Broecheler</p>
                    </Box>
                    <Box className='team-member-box'>
                        <Avatar className='avatarImage' alt='Quirijn de Mast' src={QuirijndeMast} />
                        <p>Quirijn de Mast</p>
                    </Box>
                    <Box className='team-member-box'>
                        <Avatar className='avatarImage' alt='Jeroen Schouten' src={JeroenSchouten} />
                        <p>Jeroen Schouten</p>
                    </Box>
                </div>
                <div>
                    <Box>
                        <Typography textAlign={'center'} variant="h6">
                            SPICE Advisory Board
                        </Typography>
                        <div>
                            <p>SPICE’s Advisory Board consists of distinguished and senior experts who provide strategic advice to our mission and activities, to ensure that we can best serve the global need for specialized infectious disease expertise.</p>
                        </div>
                    </Box>
                </div>
            </div>
        </StyledEngineProvider>

    )
}

export default SpiceTeamComponent;