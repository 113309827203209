import { AppRoutes } from "../routes/app-routes";

export const dropdownSubItems = {
    'Partnerships': [], 
    'Become an Expert': [
        {
            value: 'What is a SPICE expert?',
            route: AppRoutes.WhatIsSpiceExpert
        },
        // {
        //     value: 'Terms and conditions',
        //     route: '/become-expert/terms-and-conditions'
        // },
        {
            value: 'Meet our experts',
            route: AppRoutes.ExpertStories
        },
        {
            value: 'Apply',
            route: AppRoutes.ExpertApply,//'https://www.aanmelder.nl/137902/subscribe'
        }
    ],
    'Become a Trainer': [
        {
            value: 'What is AMS trainer?',
            route: AppRoutes.WhatIsAMSTrainer
        },
        // {
        //     value: 'Terms and conditions',
        //     route: '/become-trainer/terms-and-conditions'
        // },
        {
            value: 'Our trainers',
            route: '/#/expert-stories'
        },
        {
            value: 'Apply',
            route: AppRoutes.TrainerApply
        }
    ],

    'Request support': [
        // {
        //     value: 'Areas of support',
        //     route: '/request-support/areas-of-support'
        // },
        {
            value: 'Types of support',
            route: AppRoutes.TypesOfSupport
        },
        // {
            // value: 'Terms and conditions',
            // route: '/request-support/terms-and-conditions',// RETURN WHEN CONTENT IS PROVIDED  '/request-support/terms-and-conditions'
        // },
        {
            value: 'How can SPICE help my facility?',
            route: AppRoutes.HowCanSpiceHelpMyFacility
        },
        {
            value: 'Apply',
            route: AppRoutes.RequestSupportApply
        }
    ],

    'About': [
        {
            value: 'About SPICE',
            route: AppRoutes.AboutSpice
        },
        {
            value: 'Where we work',
            route: AppRoutes.WhereWeWork
        },
        {
            value: 'SPICE Team',
            route: AppRoutes.SpiceTeam
        },
        // {
        //     value: 'Get involved',
        //     route: '/about/get-involved'
        // },
        {
            value: 'Contact Us',
            route: AppRoutes.ContactUs
        }
    ]
};