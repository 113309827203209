import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Box, Button, CardActionArea, CardActions, CardHeader, Container, Grid, ImageList } from '@mui/material';
import news1 from '../../media/rsz1.png';
import news2 from '../../media/rsz2.png';
import news3 from '../../media/rsz3.jpg';
import { useState } from 'react';
import { getContentBySortOrder } from '../../services/content-service'
import { useEffect } from 'react';
import './featured-story.css';
import { Link, useNavigate } from 'react-router-dom'
import { AppRoutes } from '../../consts/routes/app-routes';

const FeaturedStory = () => {
    const [data, setData] = useState([]);
    const images = [
        // {
        //     label: 'Successful prophylaxis in orthopedic surgery intervention in Armenia',
        //     imgPath: news1,
        //     description: 'A senior clinical microbiologist visit to Armenian hospital leads to an important outcome of reducing antibiotic prophylaxis for orthopaedic surgery from 5 days to a single day. He presented the evidence to a large group of orthopedic surgeons who afterwards changed their practice.'
        // },
        // {
        //     label: 'AMS Masterclass helps setup hospital Antimicrobial Stewardship program',
        //     imgPath: news2,
        //     description: 'Dr Christian Lanckohr and his team from the University Hospital Muenster, Germany have managed to establish a sustainable AMS program in their hospital following the AMS Masterclass & expert consultancy in 2019. With the attention on AMS being critically important part of patient care and a matter of patient safety, the hospital established a functional AMS-team with hospital-wide authority and dedicated hours. With the expert consultancy support, the team established regular AMS-rounds into Orthopedics, Oncology and Cardiac Surgery departments, yeilding results in reduced surgical prophylaxis. This showcase of the importance of consultative multi-disciplinary approach in dealing with appropriate antibiotic use serves as an example for other hospitals in the region, including ambulatory settings, through their „AMS Network“.'
        // },
        {
            label: 'PAHO project to analyze the infection care system in Surinam',
            imgPath: news3,
            description: ' Prof Heiman Wertheim lead a PAHO mission in Surinam to analyze the infection care system. Suriam is a relatively small country and therefore it is a huge challenge to maintain enough people trained for infection control, clinical microbiology and antimicrobial treatment. An important recommendation was to organize this community in a society (VMS) and initiate a national infection committee and antibiotic policy committee. Considering the challenge of getting reagents for microbiology testing in Surinam It was recommended to install a malditof system in the AZP microbiology lab. This is currently completed. Furthermore a second person is now in training to become a  clinical microbiologist.'
        }
    ];

    useEffect(() => {
        getContentBySortOrder().then(res => {
            setData(res);
        }).catch(error => console.log(error))
    }, []);

    return (
        <div className="local-impact-container">
            <Typography variant="h4" color="text.secondary" textAlign="center" my={1.5}>
                Local Impact
            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    justifyContent: 'center',
                    gap: 2, // space between items
                }}
            >
                {data.map((item) => (
                    <Box
                        key={item.id}
                        sx={{
                            width: { xs: '80%', sm: '50%', md: '25%' }, // responsive width
                            display: 'flex', // Ensures all cards take equal height
                            flexWrap: 'wrap'
                        }}
                    >
                        <Card sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <CardActionArea sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                                {item.image && (
                                    <CardMedia
                                        component="img"
                                        height="200"
                                        image={`data:image/png;base64,${item.image}`}
                                        alt={item.title}
                                        className="image-for-content"
                                    />
                                )}
                                <CardContent sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
                                    <Typography gutterBottom variant="h5" component="div" textAlign="center" className="content-element">
                                        {item.title}
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary" textAlign="justify" className="content-element">
                                        {item.synopsis}
                                    </Typography>
                                    <Box sx={{ mt: '20px' }}>
                                        <Link style={{ fontSize: 'large' }} to={`${AppRoutes.Story}/${item.slug}`}>Learn more</Link>
                                    </Box>
                                </CardContent>
                            </CardActionArea>
                        </Card>
                    </Box>
                ))}
            </Box>
        </div>

    );
}

export default FeaturedStory;