import { APIRoutes } from "../consts/routes/api-routes";
import API from "./base-service";


export const getContentBySlug = async (slug) => {
    try{
        const result = await API.get(`${APIRoutes.GetContentBySlug}/${slug}`);
        return result.data;
    } catch(error){
        console.log(error);
        return 'An error occured when retrieving with slug.'
    }

}

export const getContentBySortOrder = async() => {
    try{
        const result = await API.get(`${APIRoutes.SortByOrder}`)
        return result.data;
    } catch(error){
        return 'An error occured while retrieving content.'
    }
}


export const getAllContent = async () => {
    try{
        const result = await API.get(APIRoutes.Content);
        return result.data
    } catch(err){
        return null
    }
}

export const addContent = async (body) => {
    try{
        const response = await API.post(APIRoutes.Content, JSON.stringify(body), {headers: {'Content-Type': 'application/json'}})
        if(response.status === 200){
            return "Post added succesfully"
        }
        
    } catch(error){
        console.log(error);
        return 'An error occured.'
    }
}

export const updateContent = async (id, body) => {
    try{
        const response = await API.put(`${APIRoutes.Content}/${id}`, body, {headers: {'Content-Type': 'application/json'}});
        if(response.status === 200){
            return 'Content updated succesfully.'
        }
    } catch(error){
        console.log(error)
        return 'An error occured when updating content'
    }
}

export const getContentById = async (id) => {
    try{
        const result = await API.get(`${APIRoutes.Content}/${id}`);
        return result.data
    } catch(err){
        return null
    }
}

export const deleteContent = async (id) => {
    try{
        const response = await API.put(`${APIRoutes.DeleteContent}/${id}`);
        if(response.status === 200){
            return response;
        }
    } catch(error){
        console.log(error);
        return 'An error occured while deleting the content.'
    }
}